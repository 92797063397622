@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&family=Roboto&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");
@import url("https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css");
@import 'variables.scss';

@function background-opacity($color, $alpha) {
  @return rgba(red($color), green($color), blue($color), $alpha);
}

html {
  margin: 0;
  padding: 0;
  font-size: 16px;
  height: 100vh;
}

@media screen and (max-width: 320px) {
  html {
    font-size: calc(14px + 6 * ((100vw - 320px) / 680));
  }
}

@media screen and (max-width: 1280px) {
  html {
    font-size: 14px;
  }
}

body {
  font-size: 1rem;
  height: 100%;
  margin: 0 auto;
  color: $outlined;
  font-family: "Poppins", sans-serif;
  background-color: $gradiant-dashboard !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: #8f8e8e;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(112, 112, 112);
}

::-webkit-scrollbar-thumb:active {
  background-color: rgb(128, 128, 128);
}

.overflow-x {
  overflow-x: auto;
}

.fs-7 {
  font-size: 0.9rem;
}

.fs-8 {
  font-size: 0.75rem;
}

.fs-9 {
  font-size: 0.6rem;
}

.bg-gradiant-cards {
  background: $gradiant-cards;
}

.bg-moss {
  background-color: rgba($color-moss, var(--bs-bg-opacity)) !important;
}

.bg-moss-green {
  background-color: rgba($color-moss-green, var(--bs-bg-opacity)) !important;
}

.bg-danger-emphasis {
  background-color: rgba($danger-emphasis, var(--bs-bg-opacity)) !important;  
}

.bg-bordo {
  background-color: rgba($color-bordo, var(--bs-bg-opacity)) !important;
}

.bg-header {
  background-color: rgba($color-header, var(--bs-bg-opacity)) !important;
}

.bg-light {
  background-color: rgba($color-gray-1, var(--bs-bg-opacity)) !important;
}

.bg-gray {
  background-color: rgba($color-gray-2, var(--bs-bg-opacity)) !important;
}

.bg-primary {
  background-color: rgba($contained, var(--bs-bg-opacity)) !important;
}

.bg-upload {
  background-color: $color-brand-9;
  border-radius: 0.25rem;

  span {
    color: $contained;
  }

  .icon {
    background-color: $contained;
  }
}

.bg-frame-profile {
  background-image: url("../images/png/bg-frame-profile.png");
  z-index: 1;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  position: absolute;
  height: 100%;
  background-size: 100% 100%;
  top: 0;
}

.pe-none {
  pointer-events: none;
}

.modal {
  &.show {
    background: rgb(0, 0, 0, 0.5);
  }
}

.modal-backdrop {
  &.in {
    opacity: 0.9;
  }
}

.modal-sm {
  max-width: 400px;
}

.modal-md {
  max-width: 700px;
}

.modal-dialog {
  .modal-content {
    border-radius: calc(1rem - 1px);
    border: 0;

    .modal-header {
      border: 0;
    }

    .outlined {
      letter-spacing: 0.1px;
      margin-bottom: 0;
      font-family: "Poppins", sans-serif;
      color: $outlined;
    }
  }

  .close {
    padding: 0.5rem !important;

    .material-icons-outlined {
      font-size: 2.5rem;
    }
  }
}

.vh-75 {
  height: 75vh;
}

.selfie {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  position: relative;
  width: 100%;

  &.tip {
    background-image: url("../images/png/selfie-tips/tip.jpg");
    min-height: 75vh;
  }

  &.iluminacao-1 {
    background-image: url("../images/png/selfie-tips/iluminacao-1.png");
  }

  &.iluminacao-2 {
    background-image: url("../images/png/selfie-tips/iluminacao-2.png");
  }

  &.iluminacao-3 {
    background-image: url("../images/png/selfie-tips/iluminacao-3.png");
  }

  &.selfie-1 {
    background-image: url("../images/png/selfie-tips/selfie-1.png");
  }

  &.selfie-2 {
    background-image: url("../images/png/selfie-tips/selfie-2.png");
  }

  &.selfie-3 {
    background-image: url("../images/png/selfie-tips/selfie-3.png");
  }

  &.shadow-1 {
    background-image: url("../images/png/selfie-tips/shadow-1.png");
  }

  &.shadow-2 {
    background-image: url("../images/png/selfie-tips/shadow-2.png");
  }

  &.shadow-3 {
    background-image: url("../images/png/selfie-tips/shadow-3.png");
  }

  &.eyes-and-hair-1 {
    background-image: url("../images/png/selfie-tips/eyes-and-hair-1.png");
  }

  &.eyes-and-hair-2 {
    background-image: url("../images/png/selfie-tips/eyes-and-hair-2.png");
  }

  &.eyes-and-hair-3 {
    background-image: url("../images/png/selfie-tips/eyes-and-hair-3.png");
  }

  &.front-pose-1 {
    background-image: url("../images/png/selfie-tips/front-pose-1.png");
  }

  &.front-pose-2 {
    background-image: url("../images/png/selfie-tips/front-pose-2.png");
  }

  &.front-pose-3 {
    background-image: url("../images/png/selfie-tips/front-pose-3.png");
  }

  &.photo-background-1 {
    background-image: url("../images/png/selfie-tips/photo-background-1.png");
  }

  &.photo-background-2 {
    background-image: url("../images/png/selfie-tips/photo-background-2.png");
  }

  &.photo-background-3 {
    background-image: url("../images/png/selfie-tips/photo-background-3.png");
  }

  &.without-glasses-1 {
    background-image: url("../images/png/selfie-tips/without-glasses-1.png");
  }

  &.without-glasses-2 {
    background-image: url("../images/png/selfie-tips/without-glasses-2.png");
  }

  &.without-glasses-3 {
    background-image: url("../images/png/selfie-tips/without-glasses-3.png");
  }

  &.without-accessories-1 {
    background-image: url("../images/png/selfie-tips/without-accessories-1.png");
  }

  &.without-accessories-2 {
    background-image: url("../images/png/selfie-tips/without-accessories-2.png");
  }

  &.without-accessories-3 {
    background-image: url("../images/png/selfie-tips/without-accessories-3.png");
  }

  &.img-height-1 {
    min-height: 25vh;
  }

  &.img-height-2 {
    min-height: 20vh;
  }
}

.text-danger-emphasis {
  color: $danger-emphasis !important
}

.text-caution {
  color: $color-status-caution;
}

.text-shadow {
  transition: all 0.5s 0s ease;
  text-shadow: 0 0 6px #000;
  user-select: none;

  &.blurred {
    color: transparent !important;
  }
}

.text-gold {
  color: $color-gold;
}

.bg-gold {
  background-color: $color-gold;
}

.bg-caution {
  background-color: $color-status-caution;
}

img,
picture,
video,
embed,
svg {
  max-width: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.btn-group {
  .btn {
    margin: 0;
  }
}

.lh-md {
  line-height: 1.5 !important;
}

.lh-xl {
  line-height: 3 !important;
}

.lh-xxl {
  line-height: 4 !important;
}

.input-group-text {
  .form-select-sm {
    line-height: 1rem;
  }
}

.btn {
  padding: 12px 18px;
  font-weight: bold;
  transition: all 0.3s;
  margin: 0 10px;
  min-width: 129px;
  border-radius: calc(0.5rem - 1px);

  &.btn-lg {
    min-width: 90%;
  }

  &.btn-primary {
    color: $color-white !important;
    background-color: $contained !important;
    border-color: $contained !important;
  }

  &.btn-primary:hover,
  &.btn-primary.active {
    background-color: $contained-dark !important;
    border-color: $contained-dark !important;
  }

  &.btn-default:hover,
  &.btn-default.active {
    background-color: $contained !important;
    border-color: $contained !important;
    color: $color-white;
  }

  &.btn-outline-primary {
    color: $contained !important;
    border-color: $contained !important;

    &.active,
    &:hover,
    &:focus {
      background-color: $color-white !important;
      color: $contained !important;
    }
  }

  &.btn-transparent {
    background: transparent;
    color: $color-white;
    font-size: 1.2em;
  }

  &.btn-next {
    background: $color-black-2;
    color: $color-white;
  }

  &.btn-text {
    background: transparent;
    color: $color-black-2;
    font-weight: normal;
    min-width: 0;
  }

  &.link {
    background: transparent;
    color: $color-black-2;
    font-weight: normal;
    min-width: 0;
    margin: 0;
    padding: 0;
  }

  &.btn-text {
    background: transparent;
    color: $color-black-2;
    font-weight: bold;
    min-width: 0;
  }

  &.link:hover,
  .btn-text:hover {
    background-color: transparent;
    color: $contained !important;
  }

  .icon-svg {
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    align-items: center;
  }

  &.btn-sm {
    padding: 6px 18px;
    min-width: 60px;
  }

  &.btn-header {
    background-color: $color-white;
    border-color: $color-header;

    &.active {
      background-color: $color-header;
      color: $color-white;
    }
  }

  &.btn-gold {
    background-color: $color-gold;
    color: $color-white;
    border: 1px solid $color-gold;

    &:hover {
      background-color: $color-white;
      color: $color-gold !important;
      border: 1px solid $color-gold;
    }
  }

  &.btn-outline-gold {
    background-color: $color-white;
    color: $color-gold;
    border: 1px solid $color-gold;
  }
}

.btn-check {
  &:checked {
    &+.btn {
      color: $color-white !important;
      background-color: $contained !important;
    }
  }
}

span.link,
p.link {
  cursor: pointer;
  color: $contained;

  &:hover {
    text-decoration: underline;
  }
}

.text-link {
  cursor: pointer !important;
  text-decoration: underline !important;
}

.text-justify {
  text-align: justify;
}

.pointer {
  cursor: pointer;
}

.header-print {
  height: 64px;
  overflow: hidden;
  background-image: $gradient-header-print;
  position: relative;

  .logo-print {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
}

.content-finish-suitability {
  min-height: 100vh;
  overflow: hidden;
  background-image: $gradient-finish-suitability;

  .bg-logo-transparent {
    background-image: url("../images/svg/logo-bg-transparent.svg");
    background-repeat: no-repeat;
    background-size: 35% 130%;
    background-position: left top;
    min-height: 100vh;
  }
}

.content-bg-white {
  min-height: calc(100vh - 60px);
  overflow: hidden;
  background-color: $color-white;
}

.bg-logo-white {
  background-image: url("../images/svg/logo-bg-white.svg");
  background-repeat: no-repeat;
  background-position: right top;
  min-height: 100vh;
  margin-right: unset;

  &.left {
    background-position: left top;
    margin-left: unset;
  }
}

.content-objective {
  margin-top: 60px;
  min-height: calc(100vh - 60px);
}

.bg-objective {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  width: 100%;
  height: 100%;

  &.new-car {
    background-image: url("../images/png/goals/bg-new-goal-car-full.png");
  }

  &.studies {
    background-image: url("../images/png/goals/bg-new-goal-studies-full.png");
  }

  &.travels {
    background-image: url("../images/png/goals/bg-new-goal-travels-full.png");
  }

  &.new-house {
    background-image: url("../images/png/goals/bg-new-goal-house-full.png");
  }

  &.emergency-reserve {
    background-image: url("../images/png/goals/bg-new-goal-emergency-full.png");
  }

  &.marriage {
    background-image: url("../images/png/goals/bg-new-goal-marriage-full.png");
  }

  &.retirement {
    background-image: url("../images/png/goals/bg-new-goal-retirement-full.png");
  }

  &.health {
    background-image: url("../images/png/goals/bg-new-goal-health-full.png");
  }

  &.growth {
    background-image: url("../images/png/goals/bg-new-goal-growth-full.png");
  }

  &.acquisition {
    background-image: url("../images/png/goals/bg-new-goal-acquisition.png");
  }

  &.custom {
    background-image: url("../images/png/goals/bg-custom-white.png");
  }

  &.patrimony {
    background-image: url("../images/png/goals/bg-new-goal-patrimony-full.png");
  }

  &.justice {
    background-image: url("../images/png/goals/bg-new-goal-justice.png");
  }

  &.event {
    background-image: url("../images/png/goals/bg-new-goal-event.png");
  }

  &.wealth {
    background-image: url("../images/png/goals/bg-new-goal-wealth.png");
  }

  &.payment {
    background-image: url("../images/png/goals/bg-new-goal-payments.png");
  }
}

.bg-patrimony {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
  width: 100%;
  min-height: 40vh;
  background-image: url("../images/png/bg-new-patrimony-full.png");
}

#password-strength {
  width: 100%;

  &.bg-low {
    background: $bar-low;
    box-shadow: 1px 2px 6px 0 $color-gray-3;
  }

  &.bg-good {
    background: $bar-good;
    box-shadow: 1px 2px 6px 0 $color-gray-3;
  }

  &.bg-strong {
    background: $bar-strong;
    box-shadow: 1px 2px 6px 0 $color-gray-3;
  }
}

.rounded-left {
  border-top-left-radius: calc(0.5rem - 1px) !important;
  border-bottom-left-radius: calc(0.5rem - 1px) !important;
}

.rounded-right {
  border-top-right-radius: calc(0.5rem - 1px) !important;
  border-bottom-right-radius: calc(0.5rem - 1px) !important;
}

.rounded-top {
  border-top-left-radius: calc(0.5rem - 1px) !important;
  border-top-right-radius: calc(0.5rem - 1px) !important;
}

.rounded-bottom {
  border-bottom-right-radius: calc(0.5rem - 1px) !important;
  border-bottom-left-radius: calc(0.5rem - 1px) !important;
}

.rounded {
  border-radius: calc(0.5rem - 1px) !important;
}

.rounded-xl {
  border-radius: 1.5rem !important;
}

.popover-content {
  &.popover-body {
    width: 274px;
  }
}

@media screen and (max-width: 1440px) {
  .bg-patrimony {
    background-image: url("../images/png/bg-new-patrimony.png");
    height: inherit;
  }

  .bg-objective {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    width: 100%;
    height: 100%;

    &.new-car {
      background-image: url("../images/png/goals/bg-new-goal-car.png");
    }

    &.studies {
      background-image: url("../images/png/goals/bg-new-goal-studies.png");
    }

    &.travels {
      background-image: url("../images/png/goals/bg-new-goal-travels.png");
    }

    &.new-house {
      background-image: url("../images/png/goals/bg-new-goal-house.png");
    }

    &.emergency-reserve {
      background-image: url("../images/png/goals/bg-new-goal-emergency.png");
    }

    &.marriage {
      background-image: url("../images/png/goals/bg-new-goal-marriage.png");
    }

    &.retirement {
      background-image: url("../images/png/goals/bg-new-goal-retirement.png");
    }

    &.health {
      background-image: url("../images/png/goals/bg-new-goal-health.png");
    }

    &.growth {
      background-image: url("../images/png/goals/bg-new-goal-growth.png");
    }

    &.acquisition {
      background-image: url("../images/png/goals/bg-new-goal-acquisition.png");
    }

    &.custom {
      background-image: url("../images/png/goals/bg-custom-white.png");
    }

    &.patrimony {
      background-image: url("../images/png/goals/bg-new-goal-patrimony.png");
    }

    &.justice {
      background-image: url("../images/png/goals/bg-new-goal-justice.png");
    }

    &.event {
      background-image: url("../images/png/goals/bg-new-goal-event.png");
    }

    &.wealth {
      background-image: url("../images/png/goals/bg-new-goal-wealth.png");
    }

    &.payment {
      background-image: url("../images/png/goals/bg-new-goal-payments.png");
    }
  }

  .popover-content {
    &.popover-body {
      width: 230px;
    }
  }
}

@media screen and (max-width: 576px) {
  .btn-group-sm {
    .btn {
      padding: 0.25rem 0.5rem;
      font-size: 0.7rem;
      line-height: 1.5;
    }
  }
}

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: #333;
  color: $color-white;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 30px;
  font-size: 1.2em;

  .svg-icon {
    border-radius: 50%;
    line-height: 0;
    margin-right: 0.5rem;
  }
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;

  .svg-icon {
    border-radius: 50%;
    line-height: 0;
    margin-right: 0.5rem;
  }
}

#snackbar.show.error {
  background-color: $color-status-danger;

  .svg-icon {
    color: $color-status-danger;
    background-color: $color-white;
  }
}

#snackbar.show.warning {
  background-color: $color-status-warning;
  color: $color-black;

  .svg-icon {
    color: $color-status-warning;
    background-color: $color-black;
  }
}

#snackbar.show.success {
  background-color: $color-status-success;

  .svg-icon {
    color: $color-status-success;
    background-color: $color-white;
  }
}

#snackbar.show.info {
  background-color: $color-status-info;

  .svg-icon {
    color: $color-status-info;
    background-color: $color-white;
  }
}

/* Tooltip container */
.tooltip-inner {
  color: $color-black !important;
  background-color: #efbf81 !important;
}

.bs-tooltip-bottom {
  .arrow:before {
    border-bottom-color: #efbf81;
  }
}

.bs-tooltip-top {
  .arrow:before {
    border-top-color: #efbf81;
  }
}

.bs-tooltip-left {
  .arrow:before {
    border-left-color: #efbf81;
  }
}

.bs-tooltip-right {
  .arrow:before {
    border-right-color: #efbf81;
  }
}

.highlight {
  font-weight: 600;
  color: $contained !important;

  &.normal {
    font-weight: normal;
  }
}

.three-dots-vertical {
  color: $contained;
}

.is-percent::after {
  content: " %";
}

.is-currency::before {
  content: "R$ ";
}

.canvas-container {
  min-width: 100%;
  text-align: center;
  position: relative;

  .chart-value {
    width: 100%;
    top: 50%;
    left: 0;
    position: absolute;

    p,
    label {
      font-weight: bold;
      margin-bottom: 0;
    }
  }

  .chart-legend {
    display: inline-block;
    width: 100%;

    .legend {
      text-align: left;
      align-items: center;

      span {
        width: 50px;
      }

      .legend-color {
        height: 20px;
        letter-spacing: 0.5em;
        border-radius: 10px;
        margin: 0 0.5rem;
        width: 10px;
      }
    }
  }
}

.position-relative {
  .icon-end {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 1% auto 0;
  }
}

.row {
  .icon-edit {
    visibility: hidden;
    margin-right: 0.25rem;
  }

  &:hover {
    .icon-edit {
      visibility: visible;
    }
  }
}

.form-group {
  .invalid-feedback {
    font-size: 13px;
  }

  .ng-touched.ng-invalid {
    ~.invalid-feedback {
      display: block;
    }

    .form-control {
      border-color: $color-status-danger;

      &:hover,
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }

      &.lowercase {
        text-transform: lowercase;
      }

      &.uppercase {
        text-transform: uppercase;
      }
    }
  }

  >label {
    margin-bottom: .5rem;
  }
}

.form-control {
  &.ng-touched.ng-invalid {
    border-color: $color-status-danger;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }

  &.x-icon {
    padding: 0 1.5em;
  }

  ~.material-icons {
    &.search {
      cursor: pointer;
      top: 0.5rem;
      left: 1rem;
      width: 0;
      opacity: 0.5;
    }

    &.close {
      cursor: pointer;
      top: 0.6rem;
      font-size: 1rem;
      right: 0.5rem;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.form-control-upload {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.4rem;
  max-width: 100%;

  .invalid-feedback {
    margin-bottom: 1rem;
    display: block;
  }
}

input[type=checkbox],
input[type=radio] {
  accent-color: $contained;
  cursor: pointer;
}

.form-check {

  &.form-check-inline {
    display: table-cell;
  }

  input[type=checkbox],
  input[type=radio] {
    width: 15px;
    height: 15px;
  }

  label {
    cursor: pointer;
  }
}

.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option {

      &.ng-option-marked[aria-selected="true"],
      &.ng-option-selected {
        background-color: $contained !important;
        color: $color-white !important;
      }
    }
  }
}

.title {
  line-height: 1.25 !important;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 3.2em;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.2px;
  color: $outlined;
}

.title-medium {
  font-family: "Poppins", sans-serif;
  font-size: 2.4em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $color-black;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.poppins-bold {
  font-family: "Poppins-Bold";
}

.poppins-semibold {
  font-family: "Poppins-SemiBold";
}

.roboto {
  font-family: 'Roboto' !important;
}

.subtitle {
  font-size: 1.4em;
  line-height: 1.8em;
  letter-spacing: 0.4px;
  color: $outlined;
}

div.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.disabled {
  icon-svg {
    opacity: 0.5;
    pointer-events: none;
  }
}

.form-check-label {
  cursor: pointer;
  margin: 0 1rem 0 .5rem;
}

.dropdown-menu {
  width: auto !important;
  white-space: nowrap;

  .dropdown-header {
    color: $color-black;
    font-weight: bold;
    pointer-events: none;
  }

  li {
    cursor: pointer;

    label {
      margin-top: 0.5rem;
    }
  }
}

.check-circle {
  content: url("../images/png/check-circle.png");
}

.x-circle {
  content: url("../images/png/x-circle.png");
}

.color-brand-7 {
  color: $color-brand-7;
}

.color-status-error {
  color: $color-status-danger;
}

.color-status-success {
  color: $color-status-success;
}

.color-header {
  color: $color-header;
}

.text-capitalize:first-letter {
  text-transform: capitalize;
}

.input-group {
  .input-group-icon {
    display: flex;
    align-items: center;
    background-color: $color-white;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem 0 0 0.25rem;
    border-end-end-radius: 0;

    &.left {
      border-right: 0;
    }
  }

  .form-control {
    &.left {
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }
}

.btn-group-dropdown {
  z-index: 1;

  .input-search {
    position: relative;
    min-width: 270px;
    padding: 1rem 2rem;
    background-color: $color-white;

    &.left {
      border-radius: calc(0.5rem - 1px) 0 0 calc(0.5rem - 1px);
    }
  }

  .dropdown {
    .striped:nth-child(even) {
      background: $color-gray-1;
    }

    button {
      padding: 1rem 2rem;
    }

    &:first-child button {
      &::before {
        display: none;
      }

      border-radius: calc(0.5rem - 1px) 0 0 calc(0.5rem - 1px);
    }

    &:last-child button {
      border-radius: 0 calc(0.5rem - 1px) calc(0.5rem - 1px) 0;
    }

    [class*="btn"]::before {
      background: $border-default;
      content: " ";
      left: 0;
      position: absolute;
      width: 1px;
      height: 50%;
      top: 25%;
    }

    .drop-title {
      color: $drop-title;
    }

    span {
      &.drop-header {
        color: $drop-title;
        font-size: 1em;
      }

      &.options-select {
        float: left;
        height: 1.2rem;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    label {
      font-size: 13px;
    }
  }
}

.dropdown-plus {
  background-color: $contained;
  color: $color-white;
  font-weight: 900;
  border-radius: 0.25rem;
  margin-right: 1rem;
  border: 0;
}

.custom-drop-down {
  border-color: transparent !important;
  margin: 0;
  border-radius: 0;
  box-shadow: 0 0 $color-white !important;
  font-weight: normal;
  border: none !important;
  position: relative;

  &.auto-complete {
    .chevron {
      position: absolute;
      right: 0;
      top: 10px;
    }
  }

  .chevron {
    background-image: url("../images/svg/chevron-compact-down.svg");
    background-repeat: no-repeat;
    display: inline-block;
    width: 20px;
    position: relative;
    height: 13px;
    margin-left: 0.5rem;
  }

  &[aria-expanded="true"] {
    .chevron {
      background-image: url("../images/svg/chevron-compact-up.svg");
    }
  }
}

.multiselect-dropdown {
  .selected-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.card,
.card-header,
.dropdown {
  .chevron {
    background-image: url("../images/svg/chevron-compact-down.svg");
    background-repeat: no-repeat;
    display: inline-block;
    width: 20px;
    position: relative;
    height: 13px;
    margin: 0 0.5rem;
  }

  &[aria-expanded="true"] {
    .chevron {
      background-image: url("../images/svg/chevron-compact-up.svg");
    }
  }
}

.nav-title {
  cursor: pointer;
  background-color: $color-gray-1;
  font-size: 1.4em;
  text-align: center;
  padding: 1rem;
  position: relative;
}

.nav {
  &.nav-secondary {
    border-bottom: 1px solid $outline-secondary;

    .nav-link {
      color: $outline-secondary;

      &:hover {
        border-bottom-color: transparent;
      }

      &.disabled {
        color: background-opacity($outline-secondary, .25);
      }

      &.active {
        color: $contained;
        border-width: 5px;
      }
    }
  }

  &.nav-pills {
    &.nav-secondary {
      .nav-link {
        font-family: 'Poppins-semibold';

        &:not(.active) {
          color: $color-black !important;
        }

        &.active {
          background-color: background-opacity($contained, .4) !important;
        }
      }
    }
  }

  &.nav-tabs {
    border-bottom-color: $contained;

    .nav-link {
      &:not(.active) {
        color: $outline-secondary;
      }

      &.active {
        color: $contained;
        border-color: $contained $contained $gradiant-cards;
        background-color: transparent;
      }
    }
  }

  &.nav-cols {
    .nav-link {
      font-family: 'Poppins-semibold';

      &:not(.active) {
        color: $outline-secondary;
      }

      &.active {
        color: $contained;
      }
    }
  }
}

.nav-link-outline-secondary {
  display: block;
  background-color: $color-white;
  border: 1px solid $outline-secondary;
  color: $outline-secondary;
  text-align: center;
  position: relative;
  margin: -0.05rem;
  border-radius: 1rem;
  padding: 0.5rem 0;
  text-decoration: none;

  &.disabled {
    color: background-opacity($outline-secondary, .25);
    border-color: background-opacity($outline-secondary, .25);
  }

  &.active {
    background-color: background-opacity($contained, .5);
    border: 2px solid $contained;
    color: $color-white;
  }
}

bs-sortable::ng-deep .sortable-item {
  svg {
    cursor: grab;
    cursor: -webkit-grab;
  }
}

.w {
  &-10 {
    width: 10%;
  }

  &-15 {
    width: 15%;
  }

  &-20 {
    width: 20%;
  }

  &-25 {
    width: 25%;
  }

  &-75 {
    width: 75%;
  }
}

.badge-elasbank {
  font-weight: normal;
  font-size: 1.6em;
  background-color: $badge-elasbank;
  color: $color-white;
  padding: 3px 14px 4px 15px;
}

.img-logo {
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  width: 20px;
  position: relative;
  height: 20px;

  &.bb {
    background-image: url("../images/png/logo-bb.png");
  }

  &.cef {
    background-image: url("../images/png/logo-cef.png");
  }

  &.easynvest {
    background-image: url("../images/png/logo-easynvest.png");
  }

  &.inter {
    background-image: url("../images/png/logo-inter.png");
  }

  &.nubank {
    background-image: url("../images/png/logo-nubank.png");
  }

  &.santander {
    background-image: url("../images/png/logo-santander.png");
  }

  &.orama {
    background-image: url("../images/png/logo-orama.jpg");
  }

  &.xp {
    background-image: url("../images/png/logo-xp.png");
  }

  &.petrobras {
    background-image: url("../images/png/logo-petrobras.png");
  }

  &.facebook {
    background-image: url("../images/png/logo-facebook.png");
  }

  &.no-img {
    background-image: url("../images/png/logo-no-img.png");
  }

  &.elasbank {
    background-image: url("../images/png/logo-elasbank.png");
  }

  &.bradesco {
    background-image: url("../images/png/logo-bradesco.png");
  }

  &.itau {
    background-image: url("../images/png/logo-itau.png");
  }
}

.profile-image {
  z-index: 0;
  width: 360px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 360px;
  background-size: 360px 100%;
}

.profile-circle {
  background-color: #aaa;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  overflow: hidden;
  position: relative;

  img {
    &.rounded-circle {
      border: 5px solid white;
      border-radius: 2rem;
    }

    position: absolute;
    bottom: 0;
    width: 100%;
    top: 0;
  }
}

.profile-square {
  background-color: #aaa;
  border-radius: 50%;
  width: 200px;
  height: 400px;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

// SVG COLOR CHANGE WITH BACKGROUND CLASS AND MASK
.icon-svg {
  width: 16px;
  height: 16px;
  right: 0px;
  cursor: pointer;

  &.help {
    mask: url("../images/svg/help-circle.svg") no-repeat center / contain;
    -webkit-mask: url("../images/svg/help-circle.svg") no-repeat center / contain;
  }

  &.svg-dots-vertical {
    mask: url("../images/svg/three-dots-vertical.svg") no-repeat center / contain;
    -webkit-mask: url("../images/svg/three-dots-vertical.svg") no-repeat center / contain;
  }

  &.bg-contained {
    background-color: $contained;
  }
}

// PAGINATE
.custom-pagination {
  .ngx-pagination {
    margin: 0 auto;
    text-align: center;
    font-size: 1rem;

    &.responsive {
      li {
        width: auto;
      }
    }

    .current {
      background: $contained;
      color: $color-white !important;
    }

    li {
      margin: 0.2rem;
      border: solid 1px $contained;
      border-radius: 6px;
      width: 2rem;
      color: $contained;

      &.pagination-next,
      &.pagination-previous {
        width: auto;

        span {
          display: none;
        }

        a {
          font-size: 0;

          &::after,
          &::before {
            font-size: 1rem;
          }
        }
      }
    }

    a {
      span {
        display: flex;
        justify-content: center;
      }
    }

    a,
    button {

      &:hover,
      &:focus,
      &:visited,
      &:active {
        background-color: transparent;
        border-radius: 6px;
        font-weight: bold;
        border: 0;
        outline: none;
      }
    }
  }
}

// FIM PAGINATE

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

.table-responsive {
  overflow: inherit;
}

.table-striped-even {
  tbody {
    tr:nth-child(even):not(.row-group) {
      td {
        background-color: $color-striped;
      }
    }
  }
}

.table-transparent {
  --bs-table-bg: transparent !important
}

::ng-deep.bs-calendar-container {
  .bs-media-container {
    bs-calendar-layout {
      .bs-datepicker-body {
        table {
          tbody {
            tr {
              display: table-row !important;
              border-radius: 0 !important;
              box-shadow: 0 0 0 0 !important;
              padding: 0 !important;

              td {
                display: table-cell !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .table-responsive .dropdown-menu {
    position: static !important;
  }
}

@media (min-width: 768px) {
  .table-responsive {
    overflow: inherit;
  }
}

@media (max-width: 360px) {
  .selfie {
    &.tip {
      min-height: 50vh;
    }

    &.img-height-1 {
      min-height: 54vh;
    }

    &.img-height-2 {
      min-height: 35vh;
      margin: 0.5rem;
    }
  }

  .btn-block {
    margin: 0.5rem 0;
  }

  .btn-sm-block {
    display: block;
    width: 80%;
    margin: 0.5rem 0;
  }

  .profile-square {
    height: 200px;
  }
}

.options-select.smaller {
  font-size: 0.9em !important;
}

.cursor-pointer {
  cursor: pointer;
}

.expand {
  transition: all 0.2s ease-in-out;
}

.expand:hover {
  transform: scale(1.05);
  z-index: 1;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Firefox */
}

.font-black {
  color: black !important;
}

.font-light {
  color: #929292 !important;
}

.font-white {
  color: white !important;
}

.underline {
  text-decoration: underline !important;
}

.background-gradient {
  background: linear-gradient(68deg,
      rgba(196, 110, 114, 1) 0%,
      rgba(210, 123, 95, 1) 25%,
      rgba(239, 191, 129, 1) 50%,
      rgba(224, 213, 164, 1) 75%,
      rgba(180, 213, 201, 1) 100%);

  .logo {
    background: url("../../assets/images/png/logo_ladybank.png") no-repeat center left;
    height: 30px;
    width: 100%;
  }
}

/******
TABLE RESPONSIVE
*******/
table {
  border: 0;
  width: 100%;
  border-radius: 1rem;

  thead {
    display: table-header-group;
  }

  tbody {
    tr {
      display: table-row;
    }
  }

  tr {
    height: 45px;
  }

  th,
  td {
    padding: 0 0.5rem;

    &:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-left-radius: 0.375rem;
    }

    &:last-child {
      border-bottom-right-radius: 0.375rem;
      border-top-right-radius: 0.375rem;
    }
  }

  th {
    color: $outlined;
  }

  td {
    display: table-cell;
    color: $color-gray-4;
  }

  .tr-title {
    th {
      &:first-child {
        border-left: 1px solid $color-white !important;
      }

      border-top: 1rem solid $color-white;
      border-bottom: 1rem solid $color-white;
      border-left: 1px solid $border-default !important;
      padding: 0.5rem;

      &.border-left-0 {
        border-left: none !important;
      }
    }
  }
}

.border-primary {
  border-color: $contained !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-dashed {
  border-style: dashed !important;
}

.container {
  max-width: 1600px;
}

@media (max-width: 1440px) {
  .container {
    max-width: 1380px;
  }
}

@media (max-width: 767px) {
  table.custom-responsive {
    font-weight: 400;
    min-width: 314px;

    thead {
      display: none;
      font-weight: 500;
    }

    tbody {
      tr {
        border: 1px solid $border-default !important;
        border-radius: 5px;
        display: block;
        padding: 1rem;
        margin-bottom: 1rem;
        line-height: 1rem;

        td {
          display: block;
          font-weight: 500;
          padding: 5px;
          position: relative;
          text-align: right;
          padding-left: 28%;

          &:not(:last-child) {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          button {
            border: none;
            bottom: -2px;
            left: 50%;
            padding: 5px 0;
            position: absolute;
            transform: translate(-50%, 50%);
            transition: background 0.12s ease-out;
            width: 50%;
            padding: 0;
            margin: 0;

            &:hover,
            &:focus {
              cursor: pointer;
            }
          }

          &:before {
            content: attr(data-label);
            font-weight: 400;
            left: 5px;
            position: absolute;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 50% !important;
          }
        }
      }
    }
  }
}

/******
FIM TABLE RESPONSIVE
*******/

.watch-counter {
  background-image: url("../images/png/relogio.png");
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;

  .counter {
    position: relative;
    text-align: center;
    top: 20px;
    font-weight: bolder;
    font-family: "Geostar Fill", cursive;
    font-family: "Orbitron", sans-serif;
    font-size: 15px;
    letter-spacing: 1px;
  }
}

.btn {
  &.grow {
    transition: all 0.2s ease-in-out !important;

    &:hover {
      transform: scale(1.1) !important;
    }
  }
}

.position-absolute {
  &.center {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.faq {
  position: absolute;
  right: 2%;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.carousel-control-next,
.carousel-control-prev {
  cursor: pointer;
}

.tag-soon {
  top: -3px;
  right: -13px;
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &::before {
    content: "Em breve";
    position: absolute;
    width: 142%;
    height: 40px;
    background: #a6d6ca;
    transform: rotate(45deg) translateY(-20px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    letter-spacing: 0.1em;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 11px;
    height: 11px;
    background: #80b9ab;
    z-index: 0;
    box-shadow: -89px -89px #80b9ab;
  }
}

.column-gap-1 {
  column-gap: 1rem;
}

.collapse:not(.show) {
  display: none !important;
}

input::-webkit-input-placeholder {
  color: $border-default !important;
}

input:-moz-placeholder {
  /* Firefox 18- */
  color: $border-default !important;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  color: $border-default !important;
}

input:-ms-input-placeholder {
  color: $border-default !important;
}

.indicator {
  position: absolute;
  top: 0px;
  left: 8px;
}

.rounded-bottom-start {
  border-bottom-left-radius: var(--bs-border-radius) !important
}

.rounded-bottom-end {
  border-bottom-right-radius: var(--bs-border-radius) !important
}

.modal-full {
  --bs-modal-width: 92%;
}

.filter-error {
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-weight: bold;
  color: $color-brand-3;
}