@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&family=Roboto&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");
@import url("https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css");
@font-face {
  font-family: "Poppins-Bold";
  src: url("./Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: Poppins;
  src: url("./Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url("./Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url("./Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: Neulis;
  src: url("/assets/fonts/Neulis/regular/Neulis-Regular.otf") format("opentype");
  font-weight: regular;
  font-style: normal;
}
html {
  margin: 0;
  padding: 0;
  font-size: 16px;
  height: 100vh;
}

@media screen and (max-width: 320px) {
  html {
    font-size: calc(14px + 6 * (100vw - 320px) / 680);
  }
}
@media screen and (max-width: 1280px) {
  html {
    font-size: 14px;
  }
}
body {
  font-size: 1rem;
  height: 100%;
  margin: 0 auto;
  color: #373f41;
  font-family: "Poppins", sans-serif;
  background-color: #f2f3f7 !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: #8f8e8e;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(112, 112, 112);
}

::-webkit-scrollbar-thumb:active {
  background-color: rgb(128, 128, 128);
}

.overflow-x {
  overflow-x: auto;
}

.fs-7 {
  font-size: 0.9rem;
}

.fs-8 {
  font-size: 0.75rem;
}

.fs-9 {
  font-size: 0.6rem;
}

.bg-gradiant-cards {
  background: #f2f3f7;
}

.bg-moss {
  background-color: rgba(180, 213, 201, var(--bs-bg-opacity)) !important;
}

.bg-moss-green {
  background-color: rgba(144, 178, 132, var(--bs-bg-opacity)) !important;
}

.bg-danger-emphasis {
  background-color: rgba(185, 80, 85, var(--bs-bg-opacity)) !important;
}

.bg-bordo {
  background-color: rgba(185, 80, 85, var(--bs-bg-opacity)) !important;
}

.bg-header {
  background-color: rgba(203, 117, 104, var(--bs-bg-opacity)) !important;
}

.bg-light {
  background-color: rgba(251, 252, 253, var(--bs-bg-opacity)) !important;
}

.bg-gray {
  background-color: rgba(244, 245, 244, var(--bs-bg-opacity)) !important;
}

.bg-primary {
  background-color: rgba(43, 142, 181, var(--bs-bg-opacity)) !important;
}

.bg-upload {
  background-color: #F5FAFC;
  border-radius: 0.25rem;
}
.bg-upload span {
  color: #2b8eb5;
}
.bg-upload .icon {
  background-color: #2b8eb5;
}

.bg-frame-profile {
  background-image: url("../images/png/bg-frame-profile.png");
  z-index: 1;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  position: absolute;
  height: 100%;
  background-size: 100% 100%;
  top: 0;
}

.pe-none {
  pointer-events: none;
}

.modal.show {
  background: rgba(0, 0, 0, 0.5);
}

.modal-backdrop.in {
  opacity: 0.9;
}

.modal-sm {
  max-width: 400px;
}

.modal-md {
  max-width: 700px;
}

.modal-dialog .modal-content {
  border-radius: calc(1rem - 1px);
  border: 0;
}
.modal-dialog .modal-content .modal-header {
  border: 0;
}
.modal-dialog .modal-content .outlined {
  letter-spacing: 0.1px;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
  color: #373f41;
}
.modal-dialog .close {
  padding: 0.5rem !important;
}
.modal-dialog .close .material-icons-outlined {
  font-size: 2.5rem;
}

.vh-75 {
  height: 75vh;
}

.selfie {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  position: relative;
  width: 100%;
}
.selfie.tip {
  background-image: url("../images/png/selfie-tips/tip.jpg");
  min-height: 75vh;
}
.selfie.iluminacao-1 {
  background-image: url("../images/png/selfie-tips/iluminacao-1.png");
}
.selfie.iluminacao-2 {
  background-image: url("../images/png/selfie-tips/iluminacao-2.png");
}
.selfie.iluminacao-3 {
  background-image: url("../images/png/selfie-tips/iluminacao-3.png");
}
.selfie.selfie-1 {
  background-image: url("../images/png/selfie-tips/selfie-1.png");
}
.selfie.selfie-2 {
  background-image: url("../images/png/selfie-tips/selfie-2.png");
}
.selfie.selfie-3 {
  background-image: url("../images/png/selfie-tips/selfie-3.png");
}
.selfie.shadow-1 {
  background-image: url("../images/png/selfie-tips/shadow-1.png");
}
.selfie.shadow-2 {
  background-image: url("../images/png/selfie-tips/shadow-2.png");
}
.selfie.shadow-3 {
  background-image: url("../images/png/selfie-tips/shadow-3.png");
}
.selfie.eyes-and-hair-1 {
  background-image: url("../images/png/selfie-tips/eyes-and-hair-1.png");
}
.selfie.eyes-and-hair-2 {
  background-image: url("../images/png/selfie-tips/eyes-and-hair-2.png");
}
.selfie.eyes-and-hair-3 {
  background-image: url("../images/png/selfie-tips/eyes-and-hair-3.png");
}
.selfie.front-pose-1 {
  background-image: url("../images/png/selfie-tips/front-pose-1.png");
}
.selfie.front-pose-2 {
  background-image: url("../images/png/selfie-tips/front-pose-2.png");
}
.selfie.front-pose-3 {
  background-image: url("../images/png/selfie-tips/front-pose-3.png");
}
.selfie.photo-background-1 {
  background-image: url("../images/png/selfie-tips/photo-background-1.png");
}
.selfie.photo-background-2 {
  background-image: url("../images/png/selfie-tips/photo-background-2.png");
}
.selfie.photo-background-3 {
  background-image: url("../images/png/selfie-tips/photo-background-3.png");
}
.selfie.without-glasses-1 {
  background-image: url("../images/png/selfie-tips/without-glasses-1.png");
}
.selfie.without-glasses-2 {
  background-image: url("../images/png/selfie-tips/without-glasses-2.png");
}
.selfie.without-glasses-3 {
  background-image: url("../images/png/selfie-tips/without-glasses-3.png");
}
.selfie.without-accessories-1 {
  background-image: url("../images/png/selfie-tips/without-accessories-1.png");
}
.selfie.without-accessories-2 {
  background-image: url("../images/png/selfie-tips/without-accessories-2.png");
}
.selfie.without-accessories-3 {
  background-image: url("../images/png/selfie-tips/without-accessories-3.png");
}
.selfie.img-height-1 {
  min-height: 25vh;
}
.selfie.img-height-2 {
  min-height: 20vh;
}

.text-danger-emphasis {
  color: #B95055 !important;
}

.text-caution {
  color: #ff6517;
}

.text-shadow {
  transition: all 0.5s 0s ease;
  text-shadow: 0 0 6px #000;
  user-select: none;
}
.text-shadow.blurred {
  color: transparent !important;
}

.text-gold {
  color: #EBB764;
}

.bg-gold {
  background-color: #EBB764;
}

.bg-caution {
  background-color: #ff6517;
}

img,
picture,
video,
embed,
svg {
  max-width: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.btn-group .btn {
  margin: 0;
}

.lh-md {
  line-height: 1.5 !important;
}

.lh-xl {
  line-height: 3 !important;
}

.lh-xxl {
  line-height: 4 !important;
}

.input-group-text .form-select-sm {
  line-height: 1rem;
}

.btn {
  padding: 12px 18px;
  font-weight: bold;
  transition: all 0.3s;
  margin: 0 10px;
  min-width: 129px;
  border-radius: calc(0.5rem - 1px);
}
.btn.btn-lg {
  min-width: 90%;
}
.btn.btn-primary {
  color: #ffffff !important;
  background-color: #2b8eb5 !important;
  border-color: #2b8eb5 !important;
}
.btn.btn-primary:hover, .btn.btn-primary.active {
  background-color: #18799c !important;
  border-color: #18799c !important;
}
.btn.btn-default:hover, .btn.btn-default.active {
  background-color: #2b8eb5 !important;
  border-color: #2b8eb5 !important;
  color: #ffffff;
}
.btn.btn-outline-primary {
  color: #2b8eb5 !important;
  border-color: #2b8eb5 !important;
}
.btn.btn-outline-primary.active, .btn.btn-outline-primary:hover, .btn.btn-outline-primary:focus {
  background-color: #ffffff !important;
  color: #2b8eb5 !important;
}
.btn.btn-transparent {
  background: transparent;
  color: #ffffff;
  font-size: 1.2em;
}
.btn.btn-next {
  background: #3c4858;
  color: #ffffff;
}
.btn.btn-text {
  background: transparent;
  color: #3c4858;
  font-weight: normal;
  min-width: 0;
}
.btn.link {
  background: transparent;
  color: #3c4858;
  font-weight: normal;
  min-width: 0;
  margin: 0;
  padding: 0;
}
.btn.btn-text {
  background: transparent;
  color: #3c4858;
  font-weight: bold;
  min-width: 0;
}
.btn.link:hover,
.btn .btn-text:hover {
  background-color: transparent;
  color: #2b8eb5 !important;
}
.btn .icon-svg {
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  align-items: center;
}
.btn.btn-sm {
  padding: 6px 18px;
  min-width: 60px;
}
.btn.btn-header {
  background-color: #ffffff;
  border-color: #CB7568;
}
.btn.btn-header.active {
  background-color: #CB7568;
  color: #ffffff;
}
.btn.btn-gold {
  background-color: #EBB764;
  color: #ffffff;
  border: 1px solid #EBB764;
}
.btn.btn-gold:hover {
  background-color: #ffffff;
  color: #EBB764 !important;
  border: 1px solid #EBB764;
}
.btn.btn-outline-gold {
  background-color: #ffffff;
  color: #EBB764;
  border: 1px solid #EBB764;
}

.btn-check:checked + .btn {
  color: #ffffff !important;
  background-color: #2b8eb5 !important;
}

span.link,
p.link {
  cursor: pointer;
  color: #2b8eb5;
}
span.link:hover,
p.link:hover {
  text-decoration: underline;
}

.text-link {
  cursor: pointer !important;
  text-decoration: underline !important;
}

.text-justify {
  text-align: justify;
}

.pointer {
  cursor: pointer;
}

.header-print {
  height: 64px;
  overflow: hidden;
  background-image: linear-gradient(to top, #FBE5CC, #FBDED6);
  position: relative;
}
.header-print .logo-print {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.content-finish-suitability {
  min-height: 100vh;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #fbe0d1, #f7c1a8, #f3c536);
}
.content-finish-suitability .bg-logo-transparent {
  background-image: url("../images/svg/logo-bg-transparent.svg");
  background-repeat: no-repeat;
  background-size: 35% 130%;
  background-position: left top;
  min-height: 100vh;
}

.content-bg-white {
  min-height: calc(100vh - 60px);
  overflow: hidden;
  background-color: #ffffff;
}

.bg-logo-white {
  background-image: url("../images/svg/logo-bg-white.svg");
  background-repeat: no-repeat;
  background-position: right top;
  min-height: 100vh;
  margin-right: unset;
}
.bg-logo-white.left {
  background-position: left top;
  margin-left: unset;
}

.content-objective {
  margin-top: 60px;
  min-height: calc(100vh - 60px);
}

.bg-objective {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  width: 100%;
  height: 100%;
}
.bg-objective.new-car {
  background-image: url("../images/png/goals/bg-new-goal-car-full.png");
}
.bg-objective.studies {
  background-image: url("../images/png/goals/bg-new-goal-studies-full.png");
}
.bg-objective.travels {
  background-image: url("../images/png/goals/bg-new-goal-travels-full.png");
}
.bg-objective.new-house {
  background-image: url("../images/png/goals/bg-new-goal-house-full.png");
}
.bg-objective.emergency-reserve {
  background-image: url("../images/png/goals/bg-new-goal-emergency-full.png");
}
.bg-objective.marriage {
  background-image: url("../images/png/goals/bg-new-goal-marriage-full.png");
}
.bg-objective.retirement {
  background-image: url("../images/png/goals/bg-new-goal-retirement-full.png");
}
.bg-objective.health {
  background-image: url("../images/png/goals/bg-new-goal-health-full.png");
}
.bg-objective.growth {
  background-image: url("../images/png/goals/bg-new-goal-growth-full.png");
}
.bg-objective.acquisition {
  background-image: url("../images/png/goals/bg-new-goal-acquisition.png");
}
.bg-objective.custom {
  background-image: url("../images/png/goals/bg-custom-white.png");
}
.bg-objective.patrimony {
  background-image: url("../images/png/goals/bg-new-goal-patrimony-full.png");
}
.bg-objective.justice {
  background-image: url("../images/png/goals/bg-new-goal-justice.png");
}
.bg-objective.event {
  background-image: url("../images/png/goals/bg-new-goal-event.png");
}
.bg-objective.wealth {
  background-image: url("../images/png/goals/bg-new-goal-wealth.png");
}
.bg-objective.payment {
  background-image: url("../images/png/goals/bg-new-goal-payments.png");
}

.bg-patrimony {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
  width: 100%;
  min-height: 40vh;
  background-image: url("../images/png/bg-new-patrimony-full.png");
}

#password-strength {
  width: 100%;
}
#password-strength.bg-low {
  background: linear-gradient(90deg, rgb(192, 99, 103) 60%, transparent);
  box-shadow: 1px 2px 6px 0 #b0bec5;
}
#password-strength.bg-good {
  background: linear-gradient(90deg, rgb(192, 99, 103) 10%, rgb(243, 197, 54) 85%, transparent);
  box-shadow: 1px 2px 6px 0 #b0bec5;
}
#password-strength.bg-strong {
  background: linear-gradient(90deg, rgb(192, 99, 103) 10%, rgb(243, 197, 54) 60%, rgb(38, 165, 88) 100%);
  box-shadow: 1px 2px 6px 0 #b0bec5;
}

.rounded-left {
  border-top-left-radius: calc(0.5rem - 1px) !important;
  border-bottom-left-radius: calc(0.5rem - 1px) !important;
}

.rounded-right {
  border-top-right-radius: calc(0.5rem - 1px) !important;
  border-bottom-right-radius: calc(0.5rem - 1px) !important;
}

.rounded-top {
  border-top-left-radius: calc(0.5rem - 1px) !important;
  border-top-right-radius: calc(0.5rem - 1px) !important;
}

.rounded-bottom {
  border-bottom-right-radius: calc(0.5rem - 1px) !important;
  border-bottom-left-radius: calc(0.5rem - 1px) !important;
}

.rounded {
  border-radius: calc(0.5rem - 1px) !important;
}

.rounded-xl {
  border-radius: 1.5rem !important;
}

.popover-content.popover-body {
  width: 274px;
}

@media screen and (max-width: 1440px) {
  .bg-patrimony {
    background-image: url("../images/png/bg-new-patrimony.png");
    height: inherit;
  }
  .bg-objective {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    width: 100%;
    height: 100%;
  }
  .bg-objective.new-car {
    background-image: url("../images/png/goals/bg-new-goal-car.png");
  }
  .bg-objective.studies {
    background-image: url("../images/png/goals/bg-new-goal-studies.png");
  }
  .bg-objective.travels {
    background-image: url("../images/png/goals/bg-new-goal-travels.png");
  }
  .bg-objective.new-house {
    background-image: url("../images/png/goals/bg-new-goal-house.png");
  }
  .bg-objective.emergency-reserve {
    background-image: url("../images/png/goals/bg-new-goal-emergency.png");
  }
  .bg-objective.marriage {
    background-image: url("../images/png/goals/bg-new-goal-marriage.png");
  }
  .bg-objective.retirement {
    background-image: url("../images/png/goals/bg-new-goal-retirement.png");
  }
  .bg-objective.health {
    background-image: url("../images/png/goals/bg-new-goal-health.png");
  }
  .bg-objective.growth {
    background-image: url("../images/png/goals/bg-new-goal-growth.png");
  }
  .bg-objective.acquisition {
    background-image: url("../images/png/goals/bg-new-goal-acquisition.png");
  }
  .bg-objective.custom {
    background-image: url("../images/png/goals/bg-custom-white.png");
  }
  .bg-objective.patrimony {
    background-image: url("../images/png/goals/bg-new-goal-patrimony.png");
  }
  .bg-objective.justice {
    background-image: url("../images/png/goals/bg-new-goal-justice.png");
  }
  .bg-objective.event {
    background-image: url("../images/png/goals/bg-new-goal-event.png");
  }
  .bg-objective.wealth {
    background-image: url("../images/png/goals/bg-new-goal-wealth.png");
  }
  .bg-objective.payment {
    background-image: url("../images/png/goals/bg-new-goal-payments.png");
  }
  .popover-content.popover-body {
    width: 230px;
  }
}
@media screen and (max-width: 576px) {
  .btn-group-sm .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.7rem;
    line-height: 1.5;
  }
}
/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: #333;
  color: #ffffff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 30px;
  font-size: 1.2em;
}
#snackbar .svg-icon {
  border-radius: 50%;
  line-height: 0;
  margin-right: 0.5rem;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}
#snackbar.show .svg-icon {
  border-radius: 50%;
  line-height: 0;
  margin-right: 0.5rem;
}

#snackbar.show.error {
  background-color: #d6283a;
}
#snackbar.show.error .svg-icon {
  color: #d6283a;
  background-color: #ffffff;
}

#snackbar.show.warning {
  background-color: #ffcc16;
  color: #222222;
}
#snackbar.show.warning .svg-icon {
  color: #ffcc16;
  background-color: #222222;
}

#snackbar.show.success {
  background-color: #26a558;
}
#snackbar.show.success .svg-icon {
  color: #26a558;
  background-color: #ffffff;
}

#snackbar.show.info {
  background-color: #169dff;
}
#snackbar.show.info .svg-icon {
  color: #169dff;
  background-color: #ffffff;
}

/* Tooltip container */
.tooltip-inner {
  color: #222222 !important;
  background-color: #efbf81 !important;
}

.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #efbf81;
}

.bs-tooltip-top .arrow:before {
  border-top-color: #efbf81;
}

.bs-tooltip-left .arrow:before {
  border-left-color: #efbf81;
}

.bs-tooltip-right .arrow:before {
  border-right-color: #efbf81;
}

.highlight {
  font-weight: 600;
  color: #2b8eb5 !important;
}
.highlight.normal {
  font-weight: normal;
}

.three-dots-vertical {
  color: #2b8eb5;
}

.is-percent::after {
  content: " %";
}

.is-currency::before {
  content: "R$ ";
}

.canvas-container {
  min-width: 100%;
  text-align: center;
  position: relative;
}
.canvas-container .chart-value {
  width: 100%;
  top: 50%;
  left: 0;
  position: absolute;
}
.canvas-container .chart-value p,
.canvas-container .chart-value label {
  font-weight: bold;
  margin-bottom: 0;
}
.canvas-container .chart-legend {
  display: inline-block;
  width: 100%;
}
.canvas-container .chart-legend .legend {
  text-align: left;
  align-items: center;
}
.canvas-container .chart-legend .legend span {
  width: 50px;
}
.canvas-container .chart-legend .legend .legend-color {
  height: 20px;
  letter-spacing: 0.5em;
  border-radius: 10px;
  margin: 0 0.5rem;
  width: 10px;
}

.position-relative .icon-end {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 1% auto 0;
}

.row .icon-edit {
  visibility: hidden;
  margin-right: 0.25rem;
}
.row:hover .icon-edit {
  visibility: visible;
}

.form-group .invalid-feedback {
  font-size: 13px;
}
.form-group .ng-touched.ng-invalid ~ .invalid-feedback {
  display: block;
}
.form-group .ng-touched.ng-invalid .form-control {
  border-color: #d6283a;
}
.form-group .ng-touched.ng-invalid .form-control:hover, .form-group .ng-touched.ng-invalid .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-group .ng-touched.ng-invalid .form-control.lowercase {
  text-transform: lowercase;
}
.form-group .ng-touched.ng-invalid .form-control.uppercase {
  text-transform: uppercase;
}
.form-group > label {
  margin-bottom: 0.5rem;
}

.form-control.ng-touched.ng-invalid {
  border-color: #d6283a;
}
.form-control.ng-touched.ng-invalid:hover, .form-control.ng-touched.ng-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-control.x-icon {
  padding: 0 1.5em;
}
.form-control ~ .material-icons.search {
  cursor: pointer;
  top: 0.5rem;
  left: 1rem;
  width: 0;
  opacity: 0.5;
}
.form-control ~ .material-icons.close {
  cursor: pointer;
  top: 0.6rem;
  font-size: 1rem;
  right: 0.5rem;
}
.form-control ~ .material-icons:hover {
  opacity: 1;
}

.form-control-upload {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.4rem;
  max-width: 100%;
}
.form-control-upload .invalid-feedback {
  margin-bottom: 1rem;
  display: block;
}

input[type=checkbox],
input[type=radio] {
  accent-color: #2b8eb5;
  cursor: pointer;
}

.form-check.form-check-inline {
  display: table-cell;
}
.form-check input[type=checkbox],
.form-check input[type=radio] {
  width: 15px;
  height: 15px;
}
.form-check label {
  cursor: pointer;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked[aria-selected=true], .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: #2b8eb5 !important;
  color: #ffffff !important;
}

.title {
  line-height: 1.25 !important;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 3.2em;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.2px;
  color: #373f41;
}

.title-medium {
  font-family: "Poppins", sans-serif;
  font-size: 2.4em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222222;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.poppins-bold {
  font-family: "Poppins-Bold";
}

.poppins-semibold {
  font-family: "Poppins-SemiBold";
}

.roboto {
  font-family: "Roboto" !important;
}

.subtitle {
  font-size: 1.4em;
  line-height: 1.8em;
  letter-spacing: 0.4px;
  color: #373f41;
}

div.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.disabled icon-svg {
  opacity: 0.5;
  pointer-events: none;
}

.form-check-label {
  cursor: pointer;
  margin: 0 1rem 0 0.5rem;
}

.dropdown-menu {
  width: auto !important;
  white-space: nowrap;
}
.dropdown-menu .dropdown-header {
  color: #222222;
  font-weight: bold;
  pointer-events: none;
}
.dropdown-menu li {
  cursor: pointer;
}
.dropdown-menu li label {
  margin-top: 0.5rem;
}

.check-circle {
  content: url("../images/png/check-circle.png");
}

.x-circle {
  content: url("../images/png/x-circle.png");
}

.color-brand-7 {
  color: #267ed6;
}

.color-status-error {
  color: #d6283a;
}

.color-status-success {
  color: #26a558;
}

.color-header {
  color: #CB7568;
}

.text-capitalize:first-letter {
  text-transform: capitalize;
}

.input-group .input-group-icon {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem 0 0 0.25rem;
  border-end-end-radius: 0;
}
.input-group .input-group-icon.left {
  border-right: 0;
}
.input-group .form-control.left {
  border-radius: 0 0.25rem 0.25rem 0;
}

.btn-group-dropdown {
  z-index: 1;
}
.btn-group-dropdown .input-search {
  position: relative;
  min-width: 270px;
  padding: 1rem 2rem;
  background-color: #ffffff;
}
.btn-group-dropdown .input-search.left {
  border-radius: calc(0.5rem - 1px) 0 0 calc(0.5rem - 1px);
}
.btn-group-dropdown .dropdown .striped:nth-child(even) {
  background: #fbfcfd;
}
.btn-group-dropdown .dropdown button {
  padding: 1rem 2rem;
}
.btn-group-dropdown .dropdown:first-child button {
  border-radius: calc(0.5rem - 1px) 0 0 calc(0.5rem - 1px);
}
.btn-group-dropdown .dropdown:first-child button::before {
  display: none;
}
.btn-group-dropdown .dropdown:last-child button {
  border-radius: 0 calc(0.5rem - 1px) calc(0.5rem - 1px) 0;
}
.btn-group-dropdown .dropdown [class*=btn]::before {
  background: #e1e5e6;
  content: " ";
  left: 0;
  position: absolute;
  width: 1px;
  height: 50%;
  top: 25%;
}
.btn-group-dropdown .dropdown .drop-title {
  color: #a4aaac;
}
.btn-group-dropdown .dropdown span.drop-header {
  color: #a4aaac;
  font-size: 1em;
}
.btn-group-dropdown .dropdown span.options-select {
  float: left;
  height: 1.2rem;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-group-dropdown .dropdown label {
  font-size: 13px;
}

.dropdown-plus {
  background-color: #2b8eb5;
  color: #ffffff;
  font-weight: 900;
  border-radius: 0.25rem;
  margin-right: 1rem;
  border: 0;
}

.custom-drop-down {
  border-color: transparent !important;
  margin: 0;
  border-radius: 0;
  box-shadow: 0 0 #ffffff !important;
  font-weight: normal;
  border: none !important;
  position: relative;
}
.custom-drop-down.auto-complete .chevron {
  position: absolute;
  right: 0;
  top: 10px;
}
.custom-drop-down .chevron {
  background-image: url("../images/svg/chevron-compact-down.svg");
  background-repeat: no-repeat;
  display: inline-block;
  width: 20px;
  position: relative;
  height: 13px;
  margin-left: 0.5rem;
}
.custom-drop-down[aria-expanded=true] .chevron {
  background-image: url("../images/svg/chevron-compact-up.svg");
}

.multiselect-dropdown .selected-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card .chevron,
.card-header .chevron,
.dropdown .chevron {
  background-image: url("../images/svg/chevron-compact-down.svg");
  background-repeat: no-repeat;
  display: inline-block;
  width: 20px;
  position: relative;
  height: 13px;
  margin: 0 0.5rem;
}
.card[aria-expanded=true] .chevron,
.card-header[aria-expanded=true] .chevron,
.dropdown[aria-expanded=true] .chevron {
  background-image: url("../images/svg/chevron-compact-up.svg");
}

.nav-title {
  cursor: pointer;
  background-color: #fbfcfd;
  font-size: 1.4em;
  text-align: center;
  padding: 1rem;
  position: relative;
}

.nav.nav-secondary {
  border-bottom: 1px solid rgba(108, 117, 125, 0.5);
}
.nav.nav-secondary .nav-link {
  color: rgba(108, 117, 125, 0.5);
}
.nav.nav-secondary .nav-link:hover {
  border-bottom-color: transparent;
}
.nav.nav-secondary .nav-link.disabled {
  color: rgba(108, 117, 125, 0.25);
}
.nav.nav-secondary .nav-link.active {
  color: #2b8eb5;
  border-width: 5px;
}
.nav.nav-pills.nav-secondary .nav-link {
  font-family: "Poppins-semibold";
}
.nav.nav-pills.nav-secondary .nav-link:not(.active) {
  color: #222222 !important;
}
.nav.nav-pills.nav-secondary .nav-link.active {
  background-color: rgba(43, 142, 181, 0.4) !important;
}
.nav.nav-tabs {
  border-bottom-color: #2b8eb5;
}
.nav.nav-tabs .nav-link:not(.active) {
  color: rgba(108, 117, 125, 0.5);
}
.nav.nav-tabs .nav-link.active {
  color: #2b8eb5;
  border-color: #2b8eb5 #2b8eb5 #f2f3f7;
  background-color: transparent;
}
.nav.nav-cols .nav-link {
  font-family: "Poppins-semibold";
}
.nav.nav-cols .nav-link:not(.active) {
  color: rgba(108, 117, 125, 0.5);
}
.nav.nav-cols .nav-link.active {
  color: #2b8eb5;
}

.nav-link-outline-secondary {
  display: block;
  background-color: #ffffff;
  border: 1px solid rgba(108, 117, 125, 0.5);
  color: rgba(108, 117, 125, 0.5);
  text-align: center;
  position: relative;
  margin: -0.05rem;
  border-radius: 1rem;
  padding: 0.5rem 0;
  text-decoration: none;
}
.nav-link-outline-secondary.disabled {
  color: rgba(108, 117, 125, 0.25);
  border-color: rgba(108, 117, 125, 0.25);
}
.nav-link-outline-secondary.active {
  background-color: rgba(43, 142, 181, 0.5);
  border: 2px solid #2b8eb5;
  color: #ffffff;
}

bs-sortable::ng-deep .sortable-item svg {
  cursor: grab;
  cursor: -webkit-grab;
}

.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-75 {
  width: 75%;
}

.badge-elasbank {
  font-weight: normal;
  font-size: 1.6em;
  background-color: #49c3bc;
  color: #ffffff;
  padding: 3px 14px 4px 15px;
}

.img-logo {
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  width: 20px;
  position: relative;
  height: 20px;
}
.img-logo.bb {
  background-image: url("../images/png/logo-bb.png");
}
.img-logo.cef {
  background-image: url("../images/png/logo-cef.png");
}
.img-logo.easynvest {
  background-image: url("../images/png/logo-easynvest.png");
}
.img-logo.inter {
  background-image: url("../images/png/logo-inter.png");
}
.img-logo.nubank {
  background-image: url("../images/png/logo-nubank.png");
}
.img-logo.santander {
  background-image: url("../images/png/logo-santander.png");
}
.img-logo.orama {
  background-image: url("../images/png/logo-orama.jpg");
}
.img-logo.xp {
  background-image: url("../images/png/logo-xp.png");
}
.img-logo.petrobras {
  background-image: url("../images/png/logo-petrobras.png");
}
.img-logo.facebook {
  background-image: url("../images/png/logo-facebook.png");
}
.img-logo.no-img {
  background-image: url("../images/png/logo-no-img.png");
}
.img-logo.elasbank {
  background-image: url("../images/png/logo-elasbank.png");
}
.img-logo.bradesco {
  background-image: url("../images/png/logo-bradesco.png");
}
.img-logo.itau {
  background-image: url("../images/png/logo-itau.png");
}

.profile-image {
  z-index: 0;
  width: 360px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 360px;
  background-size: 360px 100%;
}

.profile-circle {
  background-color: #aaa;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  overflow: hidden;
  position: relative;
}
.profile-circle img {
  position: absolute;
  bottom: 0;
  width: 100%;
  top: 0;
}
.profile-circle img.rounded-circle {
  border: 5px solid white;
  border-radius: 2rem;
}

.profile-square {
  background-color: #aaa;
  border-radius: 50%;
  width: 200px;
  height: 400px;
  overflow: hidden;
  position: relative;
}
.profile-square img {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.icon-svg {
  width: 16px;
  height: 16px;
  right: 0px;
  cursor: pointer;
}
.icon-svg.help {
  mask: url("../images/svg/help-circle.svg") no-repeat center/contain;
  -webkit-mask: url("../images/svg/help-circle.svg") no-repeat center/contain;
}
.icon-svg.svg-dots-vertical {
  mask: url("../images/svg/three-dots-vertical.svg") no-repeat center/contain;
  -webkit-mask: url("../images/svg/three-dots-vertical.svg") no-repeat center/contain;
}
.icon-svg.bg-contained {
  background-color: #2b8eb5;
}

.custom-pagination .ngx-pagination {
  margin: 0 auto;
  text-align: center;
  font-size: 1rem;
}
.custom-pagination .ngx-pagination.responsive li {
  width: auto;
}
.custom-pagination .ngx-pagination .current {
  background: #2b8eb5;
  color: #ffffff !important;
}
.custom-pagination .ngx-pagination li {
  margin: 0.2rem;
  border: solid 1px #2b8eb5;
  border-radius: 6px;
  width: 2rem;
  color: #2b8eb5;
}
.custom-pagination .ngx-pagination li.pagination-next, .custom-pagination .ngx-pagination li.pagination-previous {
  width: auto;
}
.custom-pagination .ngx-pagination li.pagination-next span, .custom-pagination .ngx-pagination li.pagination-previous span {
  display: none;
}
.custom-pagination .ngx-pagination li.pagination-next a, .custom-pagination .ngx-pagination li.pagination-previous a {
  font-size: 0;
}
.custom-pagination .ngx-pagination li.pagination-next a::after, .custom-pagination .ngx-pagination li.pagination-next a::before, .custom-pagination .ngx-pagination li.pagination-previous a::after, .custom-pagination .ngx-pagination li.pagination-previous a::before {
  font-size: 1rem;
}
.custom-pagination .ngx-pagination a span {
  display: flex;
  justify-content: center;
}
.custom-pagination .ngx-pagination a:hover, .custom-pagination .ngx-pagination a:focus, .custom-pagination .ngx-pagination a:visited, .custom-pagination .ngx-pagination a:active,
.custom-pagination .ngx-pagination button:hover,
.custom-pagination .ngx-pagination button:focus,
.custom-pagination .ngx-pagination button:visited,
.custom-pagination .ngx-pagination button:active {
  background-color: transparent;
  border-radius: 6px;
  font-weight: bold;
  border: 0;
  outline: none;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}
@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
.table-responsive {
  overflow: inherit;
}

.table-striped-even tbody tr:nth-child(even):not(.row-group) td {
  background-color: #FAFAFA;
}

.table-transparent {
  --bs-table-bg: transparent !important ;
}

::ng-deep.bs-calendar-container .bs-media-container bs-calendar-layout .bs-datepicker-body table tbody tr {
  display: table-row !important;
  border-radius: 0 !important;
  box-shadow: 0 0 0 0 !important;
  padding: 0 !important;
}
::ng-deep.bs-calendar-container .bs-media-container bs-calendar-layout .bs-datepicker-body table tbody tr td {
  display: table-cell !important;
}

@media (max-width: 767px) {
  .table-responsive .dropdown-menu {
    position: static !important;
  }
}
@media (min-width: 768px) {
  .table-responsive {
    overflow: inherit;
  }
}
@media (max-width: 360px) {
  .selfie.tip {
    min-height: 50vh;
  }
  .selfie.img-height-1 {
    min-height: 54vh;
  }
  .selfie.img-height-2 {
    min-height: 35vh;
    margin: 0.5rem;
  }
  .btn-block {
    margin: 0.5rem 0;
  }
  .btn-sm-block {
    display: block;
    width: 80%;
    margin: 0.5rem 0;
  }
  .profile-square {
    height: 200px;
  }
}
.options-select.smaller {
  font-size: 0.9em !important;
}

.cursor-pointer {
  cursor: pointer;
}

.expand {
  transition: all 0.2s ease-in-out;
}

.expand:hover {
  transform: scale(1.05);
  z-index: 1;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Firefox */
}

.font-black {
  color: black !important;
}

.font-light {
  color: #929292 !important;
}

.font-white {
  color: white !important;
}

.underline {
  text-decoration: underline !important;
}

.background-gradient {
  background: linear-gradient(68deg, rgb(196, 110, 114) 0%, rgb(210, 123, 95) 25%, rgb(239, 191, 129) 50%, rgb(224, 213, 164) 75%, rgb(180, 213, 201) 100%);
}
.background-gradient .logo {
  background: url("../../assets/images/png/logo_ladybank.png") no-repeat center left;
  height: 30px;
  width: 100%;
}

/******
TABLE RESPONSIVE
*******/
table {
  border: 0;
  width: 100%;
  border-radius: 1rem;
}
table thead {
  display: table-header-group;
}
table tbody tr {
  display: table-row;
}
table tr {
  height: 45px;
}
table th,
table td {
  padding: 0 0.5rem;
}
table th:first-child,
table td:first-child {
  border-bottom-left-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}
table th:last-child,
table td:last-child {
  border-bottom-right-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
table th {
  color: #373f41;
}
table td {
  display: table-cell;
  color: #4b5456;
}
table .tr-title th {
  border-top: 1rem solid #ffffff;
  border-bottom: 1rem solid #ffffff;
  border-left: 1px solid #e1e5e6 !important;
  padding: 0.5rem;
}
table .tr-title th:first-child {
  border-left: 1px solid #ffffff !important;
}
table .tr-title th.border-left-0 {
  border-left: none !important;
}

.border-primary {
  border-color: #2b8eb5 !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-dashed {
  border-style: dashed !important;
}

.container {
  max-width: 1600px;
}

@media (max-width: 1440px) {
  .container {
    max-width: 1380px;
  }
}
@media (max-width: 767px) {
  table.custom-responsive {
    font-weight: 400;
    min-width: 314px;
  }
  table.custom-responsive thead {
    display: none;
    font-weight: 500;
  }
  table.custom-responsive tbody tr {
    border: 1px solid #e1e5e6 !important;
    border-radius: 5px;
    display: block;
    padding: 1rem;
    margin-bottom: 1rem;
    line-height: 1rem;
  }
  table.custom-responsive tbody tr td {
    display: block;
    font-weight: 500;
    padding: 5px;
    position: relative;
    text-align: right;
    padding-left: 28%;
  }
  table.custom-responsive tbody tr td:not(:last-child) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  table.custom-responsive tbody tr td button {
    border: none;
    bottom: -2px;
    left: 50%;
    padding: 5px 0;
    position: absolute;
    transform: translate(-50%, 50%);
    transition: background 0.12s ease-out;
    width: 50%;
    padding: 0;
    margin: 0;
  }
  table.custom-responsive tbody tr td button:hover, table.custom-responsive tbody tr td button:focus {
    cursor: pointer;
  }
  table.custom-responsive tbody tr td:before {
    content: attr(data-label);
    font-weight: 400;
    left: 5px;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50% !important;
  }
}
/******
FIM TABLE RESPONSIVE
*******/
.watch-counter {
  background-image: url("../images/png/relogio.png");
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}
.watch-counter .counter {
  position: relative;
  text-align: center;
  top: 20px;
  font-weight: bolder;
  font-family: "Geostar Fill", cursive;
  font-family: "Orbitron", sans-serif;
  font-size: 15px;
  letter-spacing: 1px;
}

.btn.grow {
  transition: all 0.2s ease-in-out !important;
}
.btn.grow:hover {
  transform: scale(1.1) !important;
}

.position-absolute.center {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.faq {
  position: absolute;
  right: 2%;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.carousel-control-next,
.carousel-control-prev {
  cursor: pointer;
}

.tag-soon {
  top: -3px;
  right: -13px;
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.tag-soon::before {
  content: "Em breve";
  position: absolute;
  width: 142%;
  height: 40px;
  background: #a6d6ca;
  transform: rotate(45deg) translateY(-20px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.1em;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.tag-soon::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 11px;
  height: 11px;
  background: #80b9ab;
  z-index: 0;
  box-shadow: -89px -89px #80b9ab;
}

.column-gap-1 {
  column-gap: 1rem;
}

.collapse:not(.show) {
  display: none !important;
}

input::-webkit-input-placeholder {
  color: #e1e5e6 !important;
}

input:-moz-placeholder {
  /* Firefox 18- */
  color: #e1e5e6 !important;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  color: #e1e5e6 !important;
}

input:-ms-input-placeholder {
  color: #e1e5e6 !important;
}

.indicator {
  position: absolute;
  top: 0px;
  left: 8px;
}

.rounded-bottom-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-end {
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.modal-full {
  --bs-modal-width: 92%;
}

.filter-error {
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-weight: bold;
  color: #b84f54;
}

.content-register {
  min-height: 98vh;
  overflow: hidden;
  padding-top: 60px;
  padding-top: 60px;
}
.content-register.bg-logo-yellow {
  background: url("../images/png/Background.png") no-repeat center center fixed;
  background-size: cover;
}
.content-register.bg-register {
  background: url("../images/png/bg-register.png") no-repeat center center fixed;
  background-size: cover;
}

.background-icon-photo {
  content: url("../images/svg/photo.svg");
}

.background-icon-north {
  content: url("../images/svg/north.svg");
}

.confirm-icon {
  content: url("../images/svg/success.svg");
}

.login-background {
  content: url("../images/png/login_background.png");
  background-size: cover;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.confirm-background {
  background-image: linear-gradient(#3a84f3, #f7c1a8);
  padding: 15em 0;
  height: 100vh;
  overflow: hidden;
}

.position-relative .material-icons {
  position: absolute;
  top: 6px;
  right: 6px;
}
.position-relative .material-icons.left {
  left: 0;
}

label,
p {
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #424141;
}

label.br::after,
span.br::after {
  content: "\a";
  white-space: pre;
  margin: 0;
}

.h1 {
  color: #424141;
  line-height: 40px;
  letter-spacing: 0.1px;
  margin-bottom: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.h2 {
  color: #424141;
  line-height: 40px;
  letter-spacing: 0.1px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.h5 {
  color: #424141;
}

.mt-62 {
  margin-top: 62px;
}

.card-body {
  padding: 4em;
}
.card-body.disabled {
  pointer-events: none;
  opacity: 0.1;
}

@media screen and (min-width: 780px) {
  .form-container {
    margin: 0 8%;
  }
}
app-partners {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #151515;
}
app-partners .container-partner {
  display: flex;
  flex-direction: column;
  min-height: 98vh;
}
app-partners main {
  flex: 1;
}
app-partners .bg-header-partner {
  background: linear-gradient(80.83deg, #CBDEC9 7.86%, rgba(203, 222, 201, 0.5) 50.41%, #CADECA 91.22%);
}
app-partners .bg-gray-5 {
  background-color: #E2E2E2 !important;
}
app-partners .progress-bar {
  background-color: #f4f5f4;
}
app-partners .w-20 {
  width: 20%;
}
app-partners ul.contact {
  padding: 0;
}
app-partners ul.contact li {
  background-image: url("../images/png/partners/check-circle.png");
  background-repeat: no-repeat;
  line-height: 24px;
  padding-left: 2rem;
  display: flex;
  margin-bottom: 2rem;
}

@media (min-width: 992px) {
  .pe-10 {
    padding-right: 10rem;
  }
}
/* .bs-datepicker */
/* .bs-timepicker */
/* screen size < 1024px */
/* theming */

.bs-datepicker {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background: #fff;
  box-shadow: 0 0 10px 0 #aaa;
  position: relative;
  z-index: 1;
}
.bs-datepicker:after {
  clear: both;
  content: "";
  display: block;
}
.bs-datepicker bs-day-picker {
  float: left;
}
.bs-datepicker button:hover {
  outline: none;
}
.bs-datepicker button:focus {
  outline: none;
}
.bs-datepicker button:active {
  outline: none;
}
.bs-datepicker input:hover {
  outline: none;
}
.bs-datepicker input:focus {
  outline: none;
}
.bs-datepicker input:active {
  outline: none;
}
.bs-datepicker .current-timedate {
  color: #54708b;
  font-size: 15px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  border-radius: 20px;
  border: 1px solid #e9edf0;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: uppercase;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.bs-datepicker .current-timedate span:not(:empty):before {
  content: "";
  width: 15px;
  height: 16px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: text-bottom;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAABMklEQVQoU9VTwW3CQBCcOUgBtEBKSAukAnBKME+wFCAlYIhk8sQlxFABtJAScAsuAPBEewYcxCP8ouxrPDsza61uiVN1o6RNHD4htSCmq49RfO71BvMJqBBkITRf1kmUW49nQRC9h1I5AZlBClaL8aP1fKgOOxCx8aSLs+Q19eZuNO8QmPqJRtDFguy7OAcDbJPs+/BKVPDIPrvD2ZJgWAmVe7O0rI0Vqs1seyWUXpuJoppYCa5L+U++NpNPkr5OE2oMdARsb3gykJT5ydZcL8Z9Ww60nxg2LhjON9li9OwXZzo+xLbp3nC2s9CL2RrueGyVrgwNm8HpsCzZ9EEW6kqXlo1GQe03FzP/7W8Hl0dBtu7Bf7zt6mIwvX1RvzDCm7+q3mAW0Dl/GPdUCeXrZLT9BrDrGkm4qlPvAAAAAElFTkSuQmCC);
}
.bs-datepicker .bs-media-container {
  display: flex;
}

.bs-datepicker-btns button:hover {
  outline: none;
}
.bs-datepicker-btns button:focus {
  outline: none;
}
.bs-datepicker-btns button:active {
  outline: none;
}

.bs-datepicker-predefined-btns button {
  width: 100%;
  display: block;
  height: 30px;
  background-color: #9aaec1;
  border-radius: 4px;
  color: #fff;
  border: 0;
  margin-bottom: 10px;
  padding: 0 18px;
  text-align: left;
  transition: 0.3s;
}
.bs-datepicker-predefined-btns button:active {
  outline: none;
}
.bs-datepicker-predefined-btns button:focus {
  outline: none;
}
.bs-datepicker-predefined-btns button:hover {
  background-color: #54708b;
}

.bs-datepicker-head {
  min-width: 270px;
  height: 50px;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  text-align: justify;
}
.bs-datepicker-head:after {
  content: "";
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.bs-datepicker-head button {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  height: 30px;
  line-height: 30px;
  border: 0;
  background: transparent;
  text-align: center;
  cursor: pointer;
  color: #fff;
  transition: 0.3s;
}
.bs-datepicker-head button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.bs-datepicker-head button:active {
  background-color: rgba(0, 0, 0, 0.2);
}
.bs-datepicker-head button[disabled] {
  background: rgba(221, 221, 221, 0.3);
  color: #f5f5f5;
  cursor: not-allowed;
}
.bs-datepicker-head button[disabled]:hover {
  background: rgba(221, 221, 221, 0.3);
  color: #f5f5f5;
  cursor: not-allowed;
}
.bs-datepicker-head button[disabled]:active {
  background: rgba(221, 221, 221, 0.3);
  color: #f5f5f5;
  cursor: not-allowed;
}
.bs-datepicker-head button.next {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.bs-datepicker-head button.next span {
  font-size: 28px;
  line-height: 1;
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.bs-datepicker-head button.previous {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.bs-datepicker-head button.previous span {
  font-size: 28px;
  line-height: 1;
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.bs-datepicker-head button.current {
  border-radius: 15px;
  max-width: 155px;
  padding: 0 13px;
}

.bs-datepicker-body {
  padding: 10px;
  border-radius: 0 0 3px 3px;
  min-height: 232px;
  min-width: 278px;
  border: 1px solid #e9edf0;
}
.bs-datepicker-body .days.weeks {
  position: relative;
  z-index: 1;
}
.bs-datepicker-body table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.bs-datepicker-body table th {
  font-size: 13px;
  color: #9aaec1;
  font-weight: 400;
  text-align: center;
}
.bs-datepicker-body table td {
  color: #54708b;
  text-align: center;
  position: relative;
  padding: 0;
}
.bs-datepicker-body table td span {
  display: block;
  margin: 0 auto;
  font-size: 13px;
  border-radius: 50%;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.bs-datepicker-body table td span:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 6px;
  bottom: 6px;
  left: -3px;
  right: -2px;
  box-sizing: content-box;
  background: transparent;
}
.bs-datepicker-body table td:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month) {
  cursor: pointer;
}
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: #e9edf0;
  transition: 0s;
}
.bs-datepicker-body table td span.is-active-other-month:not(.disabled):not(.selected) {
  background-color: #e9edf0;
  transition: 0s;
  cursor: pointer;
}
.bs-datepicker-body table td span.disabled {
  color: #9aaec1;
}
.bs-datepicker-body table td span.selected {
  color: #fff;
}
.bs-datepicker-body table td span.is-other-month {
  color: rgba(0, 0, 0, 0.25);
}
.bs-datepicker-body table td span.active.select-start:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  top: 0;
  border-radius: 50%;
}
.bs-datepicker-body table td span.active.select-end:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  top: 0;
  border-radius: 50%;
}
.bs-datepicker-body table td:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 6px;
  bottom: 6px;
  left: -3px;
  right: -2px;
  box-sizing: content-box;
  background: transparent;
}
.bs-datepicker-body table td:last-child.active:before {
  border-radius: 0 3px 3px 0;
  width: 125%;
  left: -25%;
}
.bs-datepicker-body table td span[class*=select-] {
  border-radius: 50%;
  color: #fff;
}
.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span {
  background-color: #e9edf0;
  transition: 0s;
}
.bs-datepicker-body table td.is-active-other-month:not(.disabled):not(.selected) span {
  background-color: #e9edf0;
  transition: 0s;
  cursor: pointer;
}
.bs-datepicker-body table td.disabled span {
  color: #9aaec1;
}
.bs-datepicker-body table td.selected span {
  color: #fff;
}
.bs-datepicker-body table td.is-other-month span {
  color: rgba(0, 0, 0, 0.25);
}
.bs-datepicker-body table td.active {
  position: relative;
}
.bs-datepicker-body table td.active.select-start:before {
  left: 35%;
}
.bs-datepicker-body table td.active.select-start span:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  top: 0;
  border-radius: 50%;
}
.bs-datepicker-body table td.active.select-start + td.active:before {
  left: -20%;
}
.bs-datepicker-body table td.active.select-end:before {
  left: -85%;
}
.bs-datepicker-body table td.active.select-end span:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  top: 0;
  border-radius: 50%;
}
.bs-datepicker-body table td[class*=select-] span {
  border-radius: 50%;
  color: #fff;
}
.bs-datepicker-body table:not(.weeks) tr td:first-child:before {
  border-radius: 3px 0 0 3px;
}
.bs-datepicker-body table.days td.active:not(.select-start):before {
  background: #e9edf0;
}
.bs-datepicker-body table.days td.active + td.is-highlighted:before {
  background: #e9edf0;
  width: 100%;
}
.bs-datepicker-body table.days td.active + td.select-end:before {
  background: #e9edf0;
  width: 100%;
}
.bs-datepicker-body table.days td.in-range:not(.select-start):before {
  background: #e9edf0;
}
.bs-datepicker-body table.days td.in-range + td.is-highlighted:before {
  background: #e9edf0;
  width: 100%;
}
.bs-datepicker-body table.days td.in-range + td.select-end:before {
  background: #e9edf0;
  width: 100%;
}
.bs-datepicker-body table.days span.active:not(.select-start):before {
  background: #e9edf0;
}
.bs-datepicker-body table.days span.in-range:not(.select-start):before {
  background: #e9edf0;
}
.bs-datepicker-body table.days span {
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.bs-datepicker-body table.days span.select-start {
  z-index: 2;
}
.bs-datepicker-body table.days span.is-highlighted.in-range:before {
  right: 3px;
  left: 0;
}
.bs-datepicker-body table.days span.in-range.select-end:before {
  right: 4px;
  left: 0;
}
.bs-datepicker-body table.days td.select-start + td.select-end:before {
  background: #e9edf0;
  width: 100%;
}
.bs-datepicker-body table.days td.select-start + td.is-highlighted:before {
  background: #e9edf0;
  width: 100%;
}
.bs-datepicker-body table.weeks tr td:nth-child(2).active:before {
  border-radius: 3px 0 0 3px;
  left: 0;
  width: 100%;
}
.bs-datepicker-body table.years td span {
  width: 46px;
  height: 46px;
  line-height: 45px;
  margin: 0 auto;
}
.bs-datepicker-body table.years tr:not(:last-child) td span {
  margin-bottom: 8px;
}
.bs-datepicker-body table.months td {
  height: 52px;
}
.bs-datepicker-body table.months td span {
  padding: 6px;
  border-radius: 15px;
}

.bs-datepicker-multiple {
  border-radius: 4px 0 0 4px;
}
.bs-datepicker-multiple + .bs-datepicker-multiple {
  margin-left: 10px;
}
.bs-datepicker-multiple .bs-datepicker {
  box-shadow: none;
  position: relative;
}
.bs-datepicker-multiple .bs-datepicker:not(:last-child) {
  padding-right: 10px;
}
.bs-datepicker-multiple .bs-datepicker + .bs-datepicker:after {
  content: "";
  display: block;
  width: 14px;
  height: 10px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAA1ElEQVQoU42RsQrCUAxF77VuDu7O4oMWW//BURBBpZvgKk4uIrjoqKOTf+DopIO4uYggtFTfw3+pkQqCW1/G5J7kJiFy4m5MxUlxAzgIPHX+lzMPzupRYlYgxiR7vqsOP8YKzsTx0yxFMCUZ+q7aZzlr+OvgoWcAFyAHgat2jLWu48252DdqAihDJGSSJNUUxYmQjs3+hPQBlAh2rG2LCOPnaw3IiGDX99TRCs7ASJsNhUOA7d/LcuHvRG22FIZvsNXw1MX6VZExCilOQKEfeLXr/10+aC9Ho7arh7oAAAAASUVORK5CYII=);
  position: absolute;
  top: 25px;
  left: -8px;
}
.bs-datepicker-multiple .bs-datepicker .left {
  float: left;
}
.bs-datepicker-multiple .bs-datepicker .right {
  float: right;
}

.bs-datepicker-container {
  padding: 15px;
}

.bs-datepicker-custom-range {
  padding: 15px;
  background: #eee;
}

.bs-datepicker-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid #e9edf0;
}
.bs-datepicker-buttons .btn-default {
  margin-left: 10px;
}
.bs-datepicker-buttons .btn-today-wrapper {
  display: flex;
  flex-flow: row wrap;
}
.bs-datepicker-buttons .clear-right {
  flex-grow: 0;
}
.bs-datepicker-buttons .today-right {
  flex-grow: 0;
}
.bs-datepicker-buttons .clear-left {
  flex-grow: 1;
}
.bs-datepicker-buttons .today-left {
  flex-grow: 1;
}
.bs-datepicker-buttons .clear-center {
  flex-grow: 0.5;
}
.bs-datepicker-buttons .today-center {
  flex-grow: 0.5;
}

.bs-timepicker-container {
  padding: 10px 0;
}

.bs-timepicker-label {
  color: #54708b;
  margin-bottom: 10px;
}

.bs-timepicker-controls {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.bs-timepicker-controls button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0;
  background-color: #e9edf0;
  color: #54708b;
  font-size: 16px;
  font-weight: 700;
  vertical-align: middle;
  line-height: 0;
  padding: 0;
  transition: 0.3s;
}
.bs-timepicker-controls button:hover {
  background-color: #d5dadd;
}
.bs-timepicker-controls input {
  width: 35px;
  height: 25px;
  border-radius: 13px;
  text-align: center;
  border: 1px solid #e9edf0;
}

.bs-timepicker .switch-time-format {
  text-transform: uppercase;
  min-width: 54px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid #e9edf0;
  background: #fff;
  color: #54708b;
  font-size: 13px;
}
.bs-timepicker .switch-time-format img {
  vertical-align: initial;
  margin-left: 4px;
}

bs-datepicker-container {
  z-index: 1080;
}

bs-daterangepicker-container {
  z-index: 1080;
}

.theme-default .bs-datepicker-head {
  background-color: #777;
}
.theme-default .bs-datepicker-predefined-btns button.selected {
  background-color: #777;
}
.theme-default .bs-datepicker-body table td span.selected {
  background-color: #777;
}
.theme-default .bs-datepicker-body table td span[class*=select-]:after {
  background-color: #777;
}
.theme-default .bs-datepicker-body table td.selected span {
  background-color: #777;
}
.theme-default .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #777;
}
.theme-default .bs-datepicker-body table td.week span {
  color: #777;
}
.theme-default .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #777;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-green .bs-datepicker-head {
  background-color: #5cb85c;
}
.theme-green .bs-datepicker-predefined-btns button.selected {
  background-color: #5cb85c;
}
.theme-green .bs-datepicker-body table td span.selected {
  background-color: #5cb85c;
}
.theme-green .bs-datepicker-body table td span[class*=select-]:after {
  background-color: #5cb85c;
}
.theme-green .bs-datepicker-body table td.selected span {
  background-color: #5cb85c;
}
.theme-green .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #5cb85c;
}
.theme-green .bs-datepicker-body table td.week span {
  color: #5cb85c;
}
.theme-green .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #5cb85c;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-blue .bs-datepicker-head {
  background-color: #2b8eb5;
}
.theme-blue .bs-datepicker-predefined-btns button.selected {
  background-color: #2b8eb5;
}
.theme-blue .bs-datepicker-body table td span.selected {
  background-color: #2b8eb5;
}
.theme-blue .bs-datepicker-body table td span[class*=select-]:after {
  background-color: #2b8eb5;
}
.theme-blue .bs-datepicker-body table td.selected span {
  background-color: #2b8eb5;
}
.theme-blue .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #2b8eb5;
}
.theme-blue .bs-datepicker-body table td.week span {
  color: #2b8eb5;
}
.theme-blue .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #2b8eb5;
  color: #ffffff;
  opacity: 0.5;
  transition: 0s;
}

.theme-dark-blue .bs-datepicker-head {
  background-color: #337ab7;
}
.theme-dark-blue .bs-datepicker-predefined-btns button.selected {
  background-color: #337ab7;
}
.theme-dark-blue .bs-datepicker-body table td span.selected {
  background-color: #337ab7;
}
.theme-dark-blue .bs-datepicker-body table td span[class*=select-]:after {
  background-color: #337ab7;
}
.theme-dark-blue .bs-datepicker-body table td.selected span {
  background-color: #337ab7;
}
.theme-dark-blue .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #337ab7;
}
.theme-dark-blue .bs-datepicker-body table td.week span {
  color: #337ab7;
}
.theme-dark-blue .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #337ab7;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-red .bs-datepicker-head {
  background-color: #d9534f;
}
.theme-red .bs-datepicker-predefined-btns button.selected {
  background-color: #d9534f;
}
.theme-red .bs-datepicker-body table td span.selected {
  background-color: #d9534f;
}
.theme-red .bs-datepicker-body table td span[class*=select-]:after {
  background-color: #d9534f;
}
.theme-red .bs-datepicker-body table td.selected span {
  background-color: #d9534f;
}
.theme-red .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #d9534f;
}
.theme-red .bs-datepicker-body table td.week span {
  color: #d9534f;
}
.theme-red .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #d9534f;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-orange .bs-datepicker-head {
  background-color: #f0ad4e;
}
.theme-orange .bs-datepicker-predefined-btns button.selected {
  background-color: #f0ad4e;
}
.theme-orange .bs-datepicker-body table td span.selected {
  background-color: #f0ad4e;
}
.theme-orange .bs-datepicker-body table td span[class*=select-]:after {
  background-color: #f0ad4e;
}
.theme-orange .bs-datepicker-body table td.selected span {
  background-color: #f0ad4e;
}
.theme-orange .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #f0ad4e;
}
.theme-orange .bs-datepicker-body table td.week span {
  color: #f0ad4e;
}
.theme-orange .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #f0ad4e;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

@media (max-width: 768px) {
  .bs-datepicker .bs-media-container {
    flex-direction: column;
  }
  .bs-datepicker-multiple {
    display: flex;
  }
  .bs-datepicker-multiple + .bs-datepicker-multiple {
    margin-top: 10px;
    margin-left: 0;
  }
}