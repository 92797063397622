@import '../styles/variables.scss';

app-partners {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #151515;

  .container-partner {
    display: flex;
    flex-direction: column;
    min-height: 98vh;
  }

  main {
    flex: 1;
  }

  .bg-header-partner {
    background: linear-gradient(80.83deg, #CBDEC9 7.86%, rgba(203, 222, 201, 0.5) 50.41%, #CADECA 91.22%);
  }

  .bg-gray-5 {
    background-color: $color-gray-5 !important;
  }

  .progress-bar {
    background-color: $color-gray-2;
  }

  .w-20 {
    width: 20%;
  }

  ul.contact {
    padding: 0;

    li {
      background-image: url("../images/png/partners/check-circle.png");
      background-repeat: no-repeat;
      line-height: 24px;
      padding-left: 2rem;
      display: flex;
      margin-bottom: 2rem;
    }
  }
}

@media (min-width: 992px) {
  .pe-10 {
    padding-right: 10rem;
  }
}