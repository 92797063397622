// brand
$color-brand-1: #3c64b1;
$color-brand-2: #fdc674;
$color-brand-3: #b84f54;
$color-brand-4: #f5c6b9;
$color-brand-5: #4bafa514;
$color-brand-6: #92cece;
$color-brand-7: #267ed6;
$color-brand-8: #00cd830d;
$color-brand-9: #F5FAFC;
$color-brand-10: #e97737;

// background
$color-white: #ffffff;
$color-gray-1: #fbfcfd;
$color-gray-2: #f4f5f4;
$color-gray-3: #b0bec5;
$color-gray-4: #4b5456;
$color-gray-5: #E2E2E2;
$color-dark-green: #063B00;
$color-brown: #AA5A37;
$color-brand-2-opacity: #fdc6740d;
$color-brand-3-opacity: #b84f540d;
$color-header: #CB7568;
$color-striped: #FAFAFA;
$color-dark-body: rgb(164, 170, 172);
$color-moss: #B4D5C9;
$color-moss-green: rgb(144, 178, 132);
$color-bordo: rgb(185, 80, 85);


// text
$color-black: #222222;
$color-black-1: #424141;
$color-black-2: #3c4858;
$color-green: #00cd83;
$color-green-opacity: rgb(0, 204, 129, 0.3);
$color-gold: #EBB764;

// status
$color-status-success: #26a558;
$color-status-warning: #ffcc16;
$color-status-caution: #ff6517;
$color-status-danger: #d6283a;
$color-status-info: #169dff;
$color-status-warning-opacity: #FFD97FFF;
$color-status-success-opacity: #E5FAF3;
$color-status-danger-opacity: #FDF4F5;
$color-status-info-opacity: #E8F6F8;

// gradiant
$gradiant-orange: linear-gradient(to top, $color-brand-2, $color-brand-4);
$gradiant-blue: linear-gradient(to top, $color-brand-6, $color-brand-5);
$gradiant-login: linear-gradient(to top, #cfc2cc, #f4ccc5);
//$gradiant-dashboard: linear-gradient(to top, #dee7e7, #ccdcf4);
//$gradiant-investment: linear-gradient(to top, #f3bfb1, #afc4df);
//$gradiant-accounts: linear-gradient(to top, #ffd8bb4f, #fedfe3de);
$gradiant-low: linear-gradient(45deg, $color-status-danger, transparent);
$gradiant-good: linear-gradient(70deg, $color-status-danger, $color-status-success, transparent);
$gradiant-strong: linear-gradient(90deg, $color-status-danger, $color-status-success, $color-dark-green);
$gradient-finish-suitability: linear-gradient(to bottom, #fbe0d1, #f7c1a8, #f3c536);
$gradient-header-print: linear-gradient(to top, #FBE5CC, #FBDED6);
//$gradient-financial-planning: linear-gradient(to bottom, #c2dfdd, #f3bfb1);
//$gradiant-cards: linear-gradient(to top, #e53a60, #f6b751);
$gradient-proof-operation: linear-gradient(to top, #ee9733, #f26577);
$gradient-payment-voucher: linear-gradient(to top, #49c3bc, #e6a844);
$gradient-card: linear-gradient(90deg, #b36e72, #d27c60, #f1d090);

// gradiant redesign
$gradiant-dashboard: #f2f3f7;
$gradiant-investment: #f2f3f7;
$gradiant-accounts: #f2f3f7;
$gradient-financial-planning: #f2f3f7;
$gradiant-cards: #f2f3f7;

//progress
$bar-low: linear-gradient(90deg, rgba(192, 99, 103, 1) 60%, transparent);
$bar-good: linear-gradient(90deg, rgba(192, 99, 103, 1) 10%, rgba(243, 197, 54, 1) 85%, transparent);
$bar-strong: linear-gradient(90deg, rgba(192, 99, 103, 1) 10%, rgba(243, 197, 54, 1) 60%, rgba(38, 165, 88, 1) 100%);
$step: linear-gradient(90deg, rgba(227, 49, 68, 0.47) 10%, rgba(243, 197, 54, 0.63) 60%, rgba(38, 165, 88, 0.2));

// button
$contained: #2b8eb5;
$contained-dark: #18799c;
$contained-opacity: #DFEEF4;
$outlined: #373f41;
$outline-secondary: rgba(108, 117, 125, .5);
$border-default: #e1e5e6;
$drop-title: #a4aaac;
$badge-elasbank: #49c3bc;

// border
$risk-low: #1791d7;
$risk-medium: #e8832c;
$risk-high: #b74b4b;
$box-shadow: rgba(0, 0, 0, .2) 0px 5px 15px;
$danger-emphasis: #B95055 !default;